@import "bootstrap/dist/css/bootstrap.css";

@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}
/************ Body ************/
body {
  font-weight: 400;
  /*font-size: 10px;*/
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}
/************ Home ************/
#wrapper {
  position: relative;
  display: flex;
  height: 100vh;
  width: 100%;
  /*z-index: 999;*/
}
#canvas {
  position: absolute;
}
#heading {
  position: relative;
  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: normal;
  width: fit-content;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  color: #fafafa;
  font-weight: 300;
  z-index: 999;
  /*font-size: 1.875em;*/
  font-size: calc(1em + 2vw);
}
#heading__line-1 {
  /*font-size: 6rem;*/
  background-color: rgba(26,26,26,0.25);
  /*animation-duration: 1.4s;
  animation-timing-function: cubic-bezier(0.21, 1.11, 0.81, 0.99);*/
}
#name_span {
  color: #ff4d5a;
  font-weight: 400;
}
#heading__line-2 {
  /*font-size: 6rem;*/
  background-color: rgba(26,26,26,0.25);
  /*animation-duration: 1.4s;
  animation-timing-function: cubic-bezier(0.21, 1.11, 0.81, 0.99);*/
}
#heading__link {
  text-decoration: none;
  color: inherit;
}
#heading_link_text {
  width: auto;
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding: 0.8rem 1.5rem;
  width: 14.5rem;
  height: 3rem;
  background-color: rgba(26,26,26,0.6);
  border: 2px solid #ff4d5a;
  border-radius: 2px;
  color: #ff4d5a;
  font-size: 1.3rem;
  /*font-size: calc(0.8em + 0.1vw);*/
  font-weight: 50;
  cursor: pointer;
  /*animation: moveInBottom 0.7s 1.2s;
  animation-fill-mode: backwards;
  animation-timing-function: cubic-bezier(0.21, 1.11, 0.81, 0.99);
  transition:0s 0s;*/
}
#heading_link_text:hover{
  transform: translateY(-0.2rem);
  transition:0.3s 0s;
  /*transform: scaleX(1.1);*/
}
#heading__arrow {
  display: inline-block;
  height: 24px;
  width: 24px;
  margin-left: 15px;
  transform: rotate(90deg);
  fill: #ff4d5a;
  /*transition: all 0.3s;*/
}
/************ Navbar Css ************/
nav.navbar {
  height: 3.8rem;
  background-color: rgba(40,40,40,1) !important;
  border-bottom: 1px solid #1a1a1a !important;
}
.nav-link {
  font-weight: 100 !important;
  color: #fff;
  letter-spacing: 1px;
  padding: 10px 17px !important;
  font-size: /*calc(1em + 2vw);*/1.3rem;
  opacity: 1 !important;
}
.red_text {
  color: #ff4d5a !important;
}
.white_text {
  color: #fff !important;
}
@media screen and (max-width: 1200px) {
  #navbar_column {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 600px) {
  .nav-link {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 450px) {
  .nav-link {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 340px) {
  .nav-link {
    font-size: 0.7rem;
    padding: 10px 10px !important;
  }
}
/************ Skills Css ************/
.section_heading {
  text-align:center;
  font-size: calc(1em + 2vw);
  font-weight: 700;
  margin-top: 2rem;
  margin-bottom: 5rem;
  z-index: 9999;
}
@media screen and (max-width: 1400px) {
  .section_heading{
    margin-top: 1.2rem;
    margin-bottom: 1.5rem;
  }
}
.section_heading::before{
  position: absolute;
  content: '';
  border-bottom: 18px solid #ff4d5a;
  width: 9.8rem;
  display: block;
  margin: 0 auto;
  position: relative;
  left: 2rem;
  top: 4rem;
  z-index: -1;
}
@media screen and (max-width: 1600px) {
  .section_heading::before{
    top: 3.4rem;
    left: 1.4rem;
    width: 9rem;
  }
}
@media screen and (max-width: 1300px) {
  .section_heading::before{
    top: 3rem;
    left: 1.4rem;
    width: 9rem;
  }
}
@media screen and (max-width: 1000px) {
  .section_heading::before{
    top: 2.6rem;
    left: 1.4rem;
    width: 8rem;
  }
}
@media screen and (max-width: 600px) {
  .section_heading::before{
    top: 2.2rem;
    left: 1.4rem;
    width: 6rem;
  }
}
@media screen and (max-width: 300px) {
  .section_heading::before{
    top: 1.9rem;
    left: 1rem;
    width: 4rem;
  }
}
#whoami {
  font-size: 1.2rem;
  font-weight: 100;
}
@media screen and (max-width: 1400px) {
  #whoami{
    margin-left: auto;
    margin-right: auto;
    width: 500px;
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 1000px) {
  #whoami{
    font-size: 1rem;
  }
}
@media screen and (max-width: 600px) {
  #whoami{
    width: 400px;
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 500px) {
  #whoami{
    width: 350px;
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 400px) {
  #whoami{
    width: 300px;
    font-size: 0.85rem;
  }
}
@media screen and (max-width: 340px) {
  #whoami{
    width: 250px;
    font-size: 0.8rem;
  }
}
#head {
  height: 16rem;
  /*width: calc(12em + 2vw);*//*15rem;*/
  margin-bottom: 0rem;
  justify-content: center;
  display: flex;
}
#head_svg {
  width: 12rem;
}
.skill {
  height: 100px;
  width: 145px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border: double 3px transparent;
  background-image: linear-gradient(#1a1a1a, #1a1a1a),radial-gradient(circle at top left, #51a2e9, #ff4d5a);
  border-radius: 10px;
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.skill img{
  height: 60px;
  width: auto;
}
.skill_text {
  font-size: 12px;
}
#skill_column {
  margin-top: 140px;
  align-items: center;
}
#skill_column_middle {
  margin-top: 80px;
  align-items: center;
}
#skill_column2 {
  margin-top: 140px;
  align-items: center;
}
#skills_1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 552px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 1400px) {
  #skills_1 {
    width: 500px;
  }
  .hidden_br {
    display: none !important;
  }
}
@media screen and (max-width: 1000px) {
  .skill{
    height: 90px;
    width: 120px;
  }
  .skill img{
    height: 50px;
    width: auto;
  }
  .skill_text {
    font-size: 14px;
  }
  #skills_1 {
    width: 400px;
  }
  #skill_column {
    margin-top: 75px;
  }
  #skill_column_middle {
    margin-top: 40px;
  }
  #skill_column2 {
    margin-top: 75px;
  }
}
@media screen and (max-width: 600px) {
  .skill{
    height: 70px;
    width: 90px;
  }
  .skill img{
    height: 40px;
    width: auto;
  }
  .skill_text {
    font-size: 11px;
  }
  #skills_1 {
    width: 320px;
  }
  #skill_column {
    margin-top: 75px;
  }
  #skill_column_middle {
    margin-top: 40px;
  }
  #skill_column2 {
    margin-top: 75px;
  }
}
@media screen and (max-width: 340px) {
  .skill{
    height: 50px;
    width: 72.5px;
  }
  .skill img{
    height: 30px;
    width: auto;
  }
  .skill_text {
    font-size: 7px;
  }
  #skills_1 {
    width: 250px;
  }
  #skill_column {
    margin-top: 75px;
  }
  #skill_column_middle {
    margin-top: 40px;
  }
  #skill_column2 {
    margin-top: 75px;
  }
}
/************ Projects Css ************/
.description{
  margin-top: 20px;
  font-size: 1.1rem;
}
.description_heading {
  font-size: 1.8rem;
  /*color: #ff5151;*/
  color: #ff6c6c;
  /*color: #ff515d;*/
}
.project_image {
  width: 600px;
  clip-path: inset(13% 5% 5% 5% round 40px);
  margin-top: 20px;
}
#wordpress_image {
  width: 500px;
  margin-bottom: 100px;
  clip-path: inset(0% 5% 0% 5% round 40px);
  /*clip-path: circle(30.6% at 50% 50%);*/
  margin-top: 60px;
  margin-left: 40px;
}
#project_row {
  margin-top: 60px;
}
@media screen and (max-width: 1000px) {
  .description{
    /*margin-right: 100px;*/
    margin-bottom: 20px;
  }
  .project_image {
    width: 400px;
    height: auto;
    margin-top: 60px;
  }
  #wordpress_image {
    width: 400px;
    height: auto;
    margin-top: 60px;
  }
}
@media screen and (max-width: 992px) {
  #project_row {
    margin-top: 30px;
  }
  #wordpress_image {
    margin-top: 80px;
  }
}
@media screen and (max-width: 767px) {
  .description{
    /*margin-right: 100px;*/
    margin-bottom: 20px;
    font-size: 0.98rem;
    /*width: 500px;*/
  }
  .description_heading {
    font-size: 1.4rem;
  }
  .project_image {
    display: none !important;
  }
  #wordpress_image {
    display: none !important;
  }
  #image_column {
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .description{
    /*margin-right: 100px;*/
    margin-bottom: 10px;
    font-size: 0.9rem;
  }
  .description_heading {
    font-size: 1.2rem;
  }
  .project_image {
    display: none !important;
  }
  #wordpress_image {
    display: none !important;
  }
  #project_row {
    
  }
  #project_container {
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    width: 80%;
  }
  #image_column {
    display: none;
  }
}
@media screen and (max-width: 340px) {
  .description{
    font-size: 0.8rem;
  }
  .description_heading {
    font-size: 1.1rem;
  }
  #project_container {
    width: 90%;
  }
}
/************ Contact Css ************/
#contact_text{
  margin-bottom: 50px;
  font-size: 20px;
}
.btn-outline-danger {
  color: rgb(255, 255, 255); /*#ff4d5a;*/
  border-color: #ff4d5a;
}
.btn {
  font-weight: 700;
  letter-spacing: 2px;
}
#submit_button {
  margin-top: 10px;
  margin-bottom: 120px;
}
#success_message {
  margin-top: 10px;
  margin-left: 10px;
  font-weight: 500;
  font-size: 15px;
}
@media screen and (max-width: 766px) {
  #center_column {
    width: 450px;
    margin-left: auto;
    margin-right: auto;
  }
  #contact_text{
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 576px) {
  #center_column {
    width: 350px;
    margin-left: auto;
    margin-right: auto;
  }
  #contact_text{
    font-size: 1rem;
  }
  #success_message {
    font-size: 13px;
    margin-left: 35px;
    margin-top: 6px;
    font-weight: 400;
  }
}
@media screen and (max-width: 400px) {
  #center_column {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  #contact_text{
    margin-bottom: 20px;
    font-size: 1rem;
  }
}
@media screen and (max-width: 340px) {
  #center_column {
    width: 270px;
    margin-left: auto;
    margin-right: auto;
  }
  #contact_text{
    margin-bottom: 20px;
    font-size: 0.8rem;
  }
}
/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
  background-color: #282828;
  height: 160px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
#up_button {
  position: relative;
  top: -25px;
  width: 40px;
  height: 45px;
  margin-right: auto;
  margin-left: auto;
  left: 0;
  right: 0;
  text-align: center;
  background-color: #ff4d5a;
  transition:0s 0s;
}
#up_button:hover {
  transform: translateY(-0.2rem);
  transition:0.3s 0s;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}